import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Welcome from './components/welcome/Welcome.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' Component={Welcome} />
      </Routes>
    </Router>
  );
}

export default App;
